<template>
  <VueAnnouncer />
  <router-view/>
</template>

<style>

* {
  box-sizing: border-box;
}

body {
  background: #eee;
  padding: 0;
  margin: 0;
}

.footer {
  font-family: sans-serif;
  display: block;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
}

#app {
  font-family: sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.5;
  background-color: white;
  font-size: 120%;
}

a {
  color: #0a1758;
}

a:hover {
  background: #f1e800;
}

button, input[type="submit"] {
  margin: .2em 0;
  padding: 1em;
  color: #f1e800;
  border: none;
  background: #0a1758;
  font-weight: 900;
  font-size: 120%;
  cursor: pointer;
  width: 100%;
}

button:disabled {
  background: darkred;
}

button:hover {
  background: #f1e800;
  color: #0a1758;
  border: none;
}

input, select, label, audio {
  display: block;
  width: 100%;
}

label {
  font-weight: bold;
}

label, select {
  font-size: 150%;
}

input {
  border: 2px solid #0a1758;
  padding: 1em;
  font-size: 120%;
  cursor: pointer;
}

nav {
  display: flex;
  border-top: 2px solid #eee;
  padding-top: 1em;
  margin-top: 3em;
}

ul {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
  text-indent: -1em;
}
</style>
