<template>
  <div>
    <h1 ref="header">Wat wil je doen?</h1>
    <p><router-link :to="{ name: 'sound.create.upload' }">Geluidsbestand uploaden</router-link></p>
    <p><router-link :to="{ name: 'sound.create.record' }">Geluid opnemen met de microfoon</router-link></p>
    <p><router-link :to="{ name: 'sound.list' }">Alle geluiden in het spel bekijken</router-link></p>
    <p><router-link :to="{ name: 'sound.draft.list' }">Geluiden controleren en goedkeuren</router-link></p>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$refs.header.focus();
  }
};
</script>
