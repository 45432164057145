<template>
  <div>
    <h1>CMS van "Raad het Geluid" van Koninklijke Visio</h1>
    <p>
      Hier kun je geluiden opnemen, uploaden, aanpassen of verwijderen.
    </p>

    <form @submit.prevent="submit">
      <label for="password">Wachtwoord</label>
      <input type="password" id="password" name="password" ref="password" v-model="password"/>
      <input type="submit" value="Inloggen"/>
    </form>
  </div>
</template>

<script>
// Password is hardcoded for now
const correctPassword = "visio";

export default {
  mounted() {
    this.$refs.password.focus();
  },
  data() {
    return { password: "" };
  },
  methods: {
    submit() {
      if (this.password === correctPassword) {
        this.$router.push({ name: 'welcome' });
      } else {
        alert('Dit is niet het juiste wachtwoord. Probeer het nog eens.');
        this.password = "";
      }
    }
  }
};
</script>
