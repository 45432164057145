import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import Welcome from '../views/Welcome.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Login,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/welcome',
    name: 'welcome',
    meta: { title: 'Wat wil je doen?' },
    component: Welcome,
  },
  {
    path: '/sounds',
    name: 'sound.list',
    props: { showDrafts: false },
    meta: { title: 'Deze geluiden zitten in het spel' },
    component: () => import(/* webpackChunkName: "sounds" */ '../views/sounds/List.vue')
  },
  {
    path: '/check-sounds',
    name: 'sound.draft.list',
    props: { showDrafts: true },
    meta: { title: 'Deze geluiden moeten nog goedgekeurd worden' },
    component: () => import(/* webpackChunkName: "sounds" */ '../views/sounds/List.vue')
  },
  {
    path: '/sounds/create/record',
    name: 'sound.create.record',
    meta: { title: 'Geluid opnemen' },
    component: () => import(/* webpackChunkName: "sounds" */ '../views/sounds/CreateRecord.vue')
  },
  {
    path: '/sounds/create/upload',
    name: 'sound.create.upload',
    meta: { title: 'Geluid uploaden' },
    component: () => import(/* webpackChunkName: "sounds" */ '../views/sounds/CreateUpload.vue')
  },
  {
    path: '/sounds/:sound_id',
    props: true,
    name: 'sound.view',
    meta: { title: 'Geluid aanpassen' },
    component: () => import(/* webpackChunkName: "sounds" */ '../views/sounds/View.vue')
  },
  {
    path: '/sounds/:sound_id/answer/:answer_index/create',
    name: 'sound.answer.create',
    props: (route) => ({ isEditing: false, answer_index: route.params.answer_index, sound_id: route.params.sound_id }),
    meta: { title: 'Antwoord aanpassen' },
    component: () => import(/* webpackChunkName: "sounds" */ '../views/sounds/AnswerView.vue')
  },
  {
    path: '/sounds/:sound_id/answer/:answer_index/edit',
    name: 'sound.answer.edit',
    props: (route) => ({ isEditing: true, answer_index: route.params.answer_index, sound_id: route.params.sound_id }),
    meta: { title: 'Antwoord aanpassen' },
    component: () => import(/* webpackChunkName: "sounds" */ '../views/sounds/AnswerView.vue')
  },
  {
    path: '/sounds/:sound_id/edit',
    name: 'sound.edit',
    props: true,
    meta: { title: 'Geluid aanpassen' },
    component: () => import(/* webpackChunkName: "sounds" */ '../views/sounds/Edit.vue')
  },
  {
    path: '/sounds/:sound_id/edit/record',
    name: 'sound.edit.record',
    props: true,
    meta: { title: 'Geluid aanpassen' },
    component: () => import(/* webpackChunkName: "sounds" */ '../views/sounds/EditRecord.vue')
  },
  {
    path: '/sounds/:sound_id/edit/upload',
    name: 'sound.edit.upload',
    props: true,
    meta: { title: 'Geluid aanpassen' },
    component: () => import(/* webpackChunkName: "sounds" */ '../views/sounds/EditUpload.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title} - Geluidsquiz`;
  } else {
    document.title = 'Geluidsquiz';
  }

  next();
});

export default router;
