import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import VueAnnouncer from '@vue-a11y/announcer';
import '@vue-a11y/announcer/dist/style.css';

createApp(App)
  .use(VueAnnouncer, { router, routeComplement: "pagina is geladen" })
  .use(router).mount('#app');
